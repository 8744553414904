<template>
    <div style="height: calc(96vh - 44px)">
    <v-tabs
    height="40"
    v-show="showTabs"
    v-model="selectedTab"
    >

    <v-tab href="#transportUnits" @click="activeTab != `fleet/transportUnits` ? $router.push({ hash:`#fleet/transportUnits` }) : ''" >
          Units
      </v-tab>
      <v-tab href="#drivers" @click="activeTab != `fleet/drivers` ? $router.push({ hash:`#fleet/drivers` }) : ''" >
              Drivers
      </v-tab>
      <v-tab href="#vehicles" @click="activeTab != `fleet/vehicles` ? $router.push({ hash:`#fleet/vehicles` }) : ''" >
              Vehicles
      </v-tab>
      <v-tab href="#trailers" @click="activeTab != `fleet/trailers` ? $router.push({ hash:`#fleet/trailers` }) : ''" >
            Trailers
      </v-tab>
      <v-tab href="#gensets" @click="activeTab != `fleet/gensets` ? $router.push({ hash:`#fleet/gensets` }) : ''" >
            Gensets
      </v-tab>


      <!-- <v-tab href="#dashboard">
          Dashboard
      </v-tab> -->

    </v-tabs>

    <v-tabs-items v-model="selectedTab">
        <v-tab-item value="dashboard">
            <FleetDashboard/>
        </v-tab-item>
        <v-tab-item value="drivers">
            <Drivers/>
        </v-tab-item>
        <v-tab-item value="vehicles">
          <Vehicles/>
        </v-tab-item>
        <v-tab-item value="trailers">
          <Trailers/>
        </v-tab-item>
        <v-tab-item value="gensets">
          <Gensets/>
        </v-tab-item>
        <v-tab-item value="transportUnits">
          <TransportUnits/>
        </v-tab-item>
    </v-tabs-items>

</div>
</template>

<script>
  import FleetDashboard from './FleetManagement/FleetDashboard.vue' 
  import Drivers from './FleetManagement/Drivers.vue'
  import Vehicles from './FleetManagement/Vehicles.vue'
  import Trailers from './FleetManagement/Trailers.vue'
  import Gensets from './FleetManagement/Gensets.vue'
  import TransportUnits from './FleetManagement/TransportUnits.vue'
    export default {
        components: {
            FleetDashboard,
            Drivers,
            Vehicles,
            Trailers,
            Gensets,
            TransportUnits
        },
        data: () => ({
    showTabs: true,
    loaded: false,
    trailerTab: false,
    vehicleTab: false,
    selectedTab:null,
    params: {
      limit: 12,
      offset: 0,
      search: null,
    },
    updateKey: 0,
    activeTab: 0,
  }),
  watch: {
    selectedTab: {
      immediate: true,
      handler( val ) {
        if ( val === 'trailers' ) {
          this.trailerTab = true
        } else {
          this.trailerTab = false
        }
        if ( val === 'vehicles' ) {
          this.vehicleTab = true
        } else {
          this.vehicleTab = false
        }
      },
    }
  },
  mounted() {
        if (window.location.hash) {
            let component = decodeURI(window.location.hash).split('#')[1];
            this.activeTab = component
        } else {
            this.$router.push({
                hash: '#fleet/transportUnits'
            })
        }
    }
    }
</script>

<style scoped>
    
</style>